<template>
  <layout-main>
    <new-login v-if="createUser" :member="member" @saveUser="refreshMember" @closed="createUser = false"></new-login>
    <div class="survey">
      <!-- Header -->
      <div class="subheader">
        <div class="subheader-main">
          <div class="subheader-left">
            <h3>{{ $t('labels.member') }} #{{ id }}</h3>
            <span class="separator-line"></span>
            <div class="breadcrumbs">
              <router-link to="/members" class="breadcrumbs-link"> {{ $t('labels.home') }}</router-link>
              <span class="separator-dot"></span>
              <router-link to="/members" class="breadcrumbs-link"> {{ $t('labels.member-register') }}</router-link>
              <span class="separator-dot"></span>
              <router-link :to="'/members/member/' + id + '/overview'" class="breadcrumbs-link"> {{ $t('labels.overview') }}</router-link>
            </div>
          </div>
          <v-guard :permissions="['auth-view-login-status']">
            <div class="subheader-right" v-if="member">
              <v-guard :permissions="['auth-create-login']">
                <button class="btn btn-sm btn-light mr-3" @click="createUser = true" v-if="!member.user">
                  <i class="fas fa-user-plus"></i> {{ $t('labels.create-login') }}
                </button>
              </v-guard>

              <button class="btn btn-sm mr-3" :class="memberHasTickets" v-if="member.user && memberHasTickets"><i class="fas fa-ticket-alt"></i> {{ $t('labels.tickets') }} A ({{getMembersTickets}})
              </button>

              <button class="btn btn-sm mr-3" :class="userNameLock" v-if="member.user && userNameLock"><i class="fas fa-user-tag"></i> {{ $t('labels.name-locked') }}
              </button>

              <button class="btn btn-sm mr-3" :class="userEmailLock" v-if="member.user && userEmailLock"><i class="fas fa-user-secret"></i> {{ $t('labels.email-locked') }}
              </button>

              <button class="btn btn-sm mr-3" :class="userRoles" v-if="member.user && userRoles"><i class="fas fa-users"></i> {{ $t('labels.authorization-active') }}
              </button>

              <button class="btn btn-sm mr-3" :class="userHidden" v-if="member.user && userHidden"><i class="fas fa-user"></i> {{ $t('labels.user-hidden') }}
              </button>

              <button class="btn btn-sm btn-success mr-3" v-if="member.user && userCreated"><i class="fas fa-user"></i> {{ $t('labels.user-created') }}
              </button>

            </div>
          </v-guard>
        </div>
      </div>
      <div class="widget overview" v-if="member">
        <div class="widget-body pr-0 pl-3">
          <div class="container-fluid">
            <div class="row">
              <div class="col-3">
                <div class="overview-main">
                  <div class="kt-profile__main-info">
                    <div class="overview-main-title">
                    <status-icon style="position: absolute; left:-5px; font-size: 1.0rem; line-height: 2.2rem;" :member="member"></status-icon>  {{ member.first_name === null ? 'Ny medlem' : member.first_name }} {{ member.last_name }}
                    </div>
                    <div class="overview-main-subtitle">
                      <a class="text-muted" :href="'mailto:' + member.email"><i class="mr-2 far fa-envelope"></i>
                        {{ member.email }}</a>
                      <div class="pt-2">
                        <i class="mt-2 fas fa-phone mr-2"></i>{{ member.phone_1 }} <i
                          class="ml-4 mt-2 fas fa-mobile-alt mr-2"></i
                      >{{ member.mobile }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="list overview-middle">
                  <div class="list-item">
                    <label>{{ $t('labels.victim-support-centre') }}</label>
                    <span v-if="member.local_office"> {{$to(member.local_office.office,'label') }}</span>
                  </div>
                  <div class="list-item">
                    <label>{{ $t('labels.local-office') }}</label>
                    <span v-if="member.local_office">{{ $to(member.local_office,'label') }}</span>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="list overview-middle">
                  <div class="list-item">
                    <label>{{ $t('labels.coordinator') }}</label>
                    <span>{{ $to(member,'coordinator_list') }}</span>
                  </div>
                  <div class="list-item">
                    <label>{{ $t('labels.support-assignment') }}</label>
                    <span>{{ $to(member,'support_list') }}</span>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="list overview-middle">
                  <div class="list-item">
                    <label>{{ $t('labels.assignment-local') }}</label>
                    <span v-if="member.local_board_assignment">{{ $to(member.local_board_assignment,'label') }}</span>
                  </div>
                  <div class="list-item">
                    <label>{{ $t('labels.assignment-regional') }}</label>
                    <span v-if="member.regional_board_assignment">{{ $to(member.regional_board_assignment,'label') }}</span>
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="list overview-last">
                  <div class="list-item">
                    <label>{{ $t('labels.assignment-national') }}</label>
                    <span v-if="member.national_board_assignment">{{ $to(member.national_board_assignment,'label') }}</span>
                  </div>

                  <div class="list-item">
                    <label>{{ $t('labels.language') }}</label>
                    <span>{{ $to(member,'language_list') }} &nbsp;</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="widget-footer with-tabs">
          <ul class="nav nav-tabs nav-tabs-line" role="tablist">
            <li class="nav-item">
              <router-link class="nav-link" :to="{ name: 'member.overview', params: { id: id } }">
                {{ $t('labels.overview') }}
              </router-link>
            </li>
            <li class="nav-item d-none">
              <router-link class="nav-link" :to="{ name: 'member.history', params: { id: id } }">{{ $t('labels.history') }}</router-link>
            </li>
          </ul>
        </div>
      </div>

      <!-- Header -->

      <router-view @updateMember="updateMember"></router-view>
    </div>
  </layout-main>
</template>

<script>
import BojAPI from '@/api/boj';
import LayoutMain from '@/views/Layouts/Main';
import NewLogin from '@/components/member/NewLogin';
import _ from 'lodash';
import StatusIcon from '@/components/member/StatusIcon';

export default {
  name: 'member-page',
  components: {
    NewLogin,
    LayoutMain,
    StatusIcon,
  },
  props: {
    id: Number,
  },
  data() {
    return {
      isLoading: true,
      memberFieldOptions: {},
      createUser: false,
      member: {
        first_name: '',
        last_name: '',
        local_office: {office: {}},
      },
    };
  },

  beforeRouteEnter(to, from, next) {
    BojAPI.getMember(to.params.id)
        .then((response) => {
          next((vm) => vm.setData(response.data));
        })
        .catch((error) => {
        });
  },
  beforeRouteUpdate(to, from, next) {
    this.member = null;
    BojAPI.getMember(to.params.id).then((response) => {
      this.setData(response.data);
      next();
    });
  },
  computed: {
    getMembersTickets() {
      if (!_.has(this.member.user, 'own_tickets')) {
        return false;
      }

      if (this.member.user.own_tickets.length) {
        return this.member.user.own_tickets.length
      }

      return 0;

    },
    memberHasTickets() {
      if (this.$store.getters.permissions.includes('auth-view-ticket-status')) {

        if (!_.has(this.member.user, 'own_tickets')) {
          return 'btn-light';
        }

        if (this.member.user.own_tickets.length) {
          return 'btn-success'
        }

        return 'btn-light';

      } else {
        return false;
      }
    },
    userNameLock() {
      if (this.$store.getters.permissions.includes('auth-view-username-lock-status')) {
        if (!_.has(this.member.user, 'lock_name')) {
          return 'btn-light';
        }

        if (this.member.user.lock_name) {
          return 'btn-success'
        }

        return 'btn-light';
      } else {
        return false;
      }
    },
    userEmailLock() {
      if (this.$store.getters.permissions.includes('auth-view-email-lock-status')) {
        if (!_.has(this.member.user, 'lock_email')) {
          return 'btn-light';
        }

        if (this.member.user.lock_email) {
          return 'btn-success'
        }

        return 'btn-light';
      } else {
        return false;
      }
    },
    userRoles() {
      if (this.$store.getters.permissions.includes('auth-view-roles-status')) {

        if (!_.has(this.member.user, 'roles')) {
          return 'btn-light';
        }

        if (this.member.user !== undefined && this.member.user.roles.length) {
          return 'btn-success'
        }

        return 'btn-light';

      } else {
        return false;
      }
    },
    userHidden() {
      if (this.$store.getters.permissions.includes('auth-view-hide-status')) {
        if (!_.has(this.member.user, 'hide_frontend')) {
          return 'btn-light';
        }

        if (this.member.user.hide_frontend) {
          return 'btn-success'
        }

        return 'btn-light';
      } else {
        return false;
      }

    },
    userCreated() {
      if (this.$store.getters.permissions.includes('auth-view-login-status')) {
        return true;
      }

      return false;
    }
  },
  methods: {
    updateMember(ticket) {
      this.setData({data: ticket});
    },

    refreshMember() {
      BojAPI.getMember(this.member.id).then((response) => {
        this.setData(response.data);
      });
    },
    setData(payload) {
      this.member = payload.data;
      this.isLoading = false;
    },
  },
};
</script>
