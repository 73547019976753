<template>
  <modal name="send-message-modal" @closed="closeSendMail" :scrollable="true" :width="800" :height="200"
         :maxHeight="600">
    <div class="clearfix">
      <h4 class="float-left">{{ $t('labels.create-login') }}</h4>
      <button type="submit" class="btn btn-success float-right mt-3 mr-3" @click="saveSearch">{{ $t('labels.create') }}</button>
      <button type="submit" class="btn btn-outline-danger float-right mt-3 mr-3" @click="closeSendMail">{{ $t('labels.cancel') }}</button>
    </div>
    <div class="container bt-1 pt-3">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="subject">{{ $t('labels.password') }}</label>
            <input type="text" v-model="password" class="form-control" id="subject" aria-describedby="emailHelp"
                   placeholder=""/>
          </div>
          <div class="form-check">
            <input class="form-check-input" v-model="notify" type="checkbox" value="" id="defaultCheck1"/>
            <label class="form-check-label" for="defaultCheck1">
              {{ $t('labels.send-new-login-message') }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>
<script>
import BojAPI from '@/api/boj';

export default {
  data() {
    return {
      notify: false,
      password: '',
    };
  },
  props: {
    member: Object,
  },
  mounted() {
    this.$modal.show('send-message-modal');
  },
  methods: {
    closeSendMail() {
      this.$emit('closed');
    },
    saveSearch() {
      if (this.password.length === 0) {
        alert(this.$t('labels.password-empty-error'));
        return false;
      }
      BojAPI.memberAssignUser(this.member, {password: this.password, notify: this.notify})
          .then(() => {
            this.$notify({
              group: 'foo',
              text: 'Login skapad för medlemmen.',
              type: 'success',
              duration: 3000,
            });
            this.$modal.hide('send-message-modal');
            this.$emit('closed');
            this.$emit('saveUser');
          })
          .catch((error) => {
            this.$notify({
              group: 'foo',
              text: this.$t('labels.email-already-exist-error'),
              type: 'warning',
              duration: 3000,
            });
          });
    },
  },
};
</script>
